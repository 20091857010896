import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
	defaultRoutes,
	adminRoutes,
	allRoutesType,
	gradientMenuItems,
} from "../../constants/routes";
import { useCurrentUser } from "../../hooks";
import Arrows from "../../assets/images/right-arrows.svg";

import LeftNavItem from "./LeftNavItem";
import styles from "./leftMenu.module.scss";

interface LeftMenuProps {
	isCollapsed?: boolean;
	collapseCallback?: (isCollapsed: boolean) => void;
}

const LeftMenu = ({ isCollapsed, collapseCallback }: LeftMenuProps) => {
	const { user } = useCurrentUser();
	const [menuCollapsed, setMenuCollapsed] = useState(!!isCollapsed);

	const { pathname } = useLocation();

	useEffect(() => {
		if (typeof isCollapsed === "boolean") {
			changeCollapse(!!isCollapsed);
		}
	}, [isCollapsed]);

	useEffect(() => {
		const allRoutes = { ...defaultRoutes, ...adminRoutes };
		const routes = Object.values(allRoutes);
		let subRoutesFlag = false;
		for (const route of routes) {
			if (pathname.startsWith(route.route) && route.subRoutes) {
				changeCollapse(true);
				subRoutesFlag = true;
			} else if (subRoutesFlag) {
				break;
			} else {
				changeCollapse(false);
			}
		}
	}, [pathname]);

	const changeCollapse = (newVal: boolean) => {
		setMenuCollapsed(newVal);
		if (collapseCallback) {
			collapseCallback(newVal);
		}
	};

	const generateMenuItems = (routes: allRoutesType) => {
		return Object.values(routes).map((el) => {
			const isTabActive = pathname.startsWith(el.route);
			if (
				{
					...user?.menu.admin,
					...user?.menu.default,
				}[el.service]
			) {
				return (
					<LeftNavItem
						key={el.service}
						menuCollapsed={menuCollapsed}
						title={el.name}
						icon={el.icon}
						itemKey={el.service}
						isTabActive={isTabActive}
						redirectRoute={el.route}
						customClass={
							gradientMenuItems?.includes(el.service) && !isTabActive ? styles.gradient : ""
						}
					/>
				);
			}
		});
	};

	return (
		<div
			className={`${styles["left-menu"]} ${menuCollapsed ? styles["collapsed-left-menu"] : ""}`}
			id="left-menu"
		>
			{!user?.is_super && <div className={styles["menu-label"]}>ME</div>}
			<LeftNavItem
				menuCollapsed={menuCollapsed}
				title={defaultRoutes["HOME"].name}
				itemKey={defaultRoutes["HOME"].service}
				icon={defaultRoutes["HOME"].icon}
				isTabActive={!!pathname.startsWith(defaultRoutes["HOME"].route)}
				redirectRoute={defaultRoutes["HOME"].route}
			/>

			{generateMenuItems(defaultRoutes)}

			{!user?.is_super && Object.keys(user?.menu.admin ?? {}).length !== 0 && (
				<div className={`${styles["menu-label"]} ${styles["organisation"]}`}>
					{user?.tenant.organization_name ?? "Organisation"}
				</div>
			)}
			{generateMenuItems(adminRoutes)}

			<div className={styles["arrow-wrapper"]} onClick={() => changeCollapse(!menuCollapsed)}>
				<img className={styles["arrows-icon"]} src={Arrows} alt="collapse-arrow" />
			</div>
		</div>
	);
};

export default LeftMenu;
